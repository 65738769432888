import React from "react";
import Testimonial from "../../components/LandingPage/Testimonial";

function Testimonials() {
  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center justify-center flex-col gap-24 w-[80vw] ">
        <h1 className="text-3xl">Testimonials</h1>
        <div className="flex flex-1 gap-12 flex-wrap items-center justify-center flex-col md:flex-row">
          <Testimonial role="Customer" text={"Slate helps you see how many more days \n" +
            "you need to work to reach your financial \n" +
            "goal for the month and year. Slate helps \n" +
            "you see how many more days \n" +
            "you need to work to reach your financial \n" +
            "goal for the month and year.your financial \n" +
            "goal for the month and year."} author="Francisco Lane"/>
          <Testimonial/>
          <Testimonial/>
          <Testimonial/>
        </div>
      </div>


    </div>
  )
}

export default Testimonials