import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import ScrollToTopButton from '../ScrollToTopButton';

function Footer() {
  return (
    <div className="border-t border-neutral-3 w-full mt-[128px]">
      <footer className="py-4">
        <div className="container mx-auto px-4 mt-[80px]">
          <div className="text-neutral-2 flex justify-between w-3/4">
            <div className="flex flex-col">
              <p className="text-neutral-8 font-bold text-[24px] mb-[32px]">
                Aizy
              </p>
              <p className="text-neutral-8 text-[24px]">
                The New Creative
              </p>
              <p className="text-neutral-8 text-[24px] mt-[-6px] mb-[8px]">
                way to trade.
              </p>
              <a
                href="/home"
                className="text-neutral-8 text-[14px]"
              >
                aizy.fintech@gmail.com
              </a>
              <a
                href="/home"
                className="text-neutral-8 text-[14px]"
              >
                5-9 Rue du Palais Rihour, 59000 Lille
              </a>
            </div>
            <div className="flex flex-col">
              <p className="text-neutral-8 text-[16px] mb-[45px]">
                Aizy
              </p>
              <a
                href="/home"
                className="text-neutral-5 text-[14px] hover:text-gray-300 mb-[24px]"
              >
                Home
              </a>
              <a
                href="/faq"
                className="text-neutral-5 text-[14px] hover:text-gray-300 mb-[24px]"
              >
                FAQ
              </a>
              <a
                href="/upload"
                className="text-neutral-5 text-[14px] hover:text-gray-300"
              >
                Upload a bot
              </a>
            </div>
            <div className="flex flex-col">
              <p className="text-neutral-8 text-[16px] mb-[45px]">
                Info
              </p>
              <a
                href="/download"
                className="text-neutral-5 text-[14px] hover:text-gray-300 mb-[24px]"
              >
                Download
              </a>
              <a
                href="/demo"
                className="text-neutral-5 text-[14px] hover:text-gray-300 mb-[24px]"
              >
                Demos
              </a>
              <a
                href="/support"
                className="text-neutral-5 text-[14px] hover:text-gray-300"
              >
                Support
              </a>
            </div>
            <div className="flex flex-col">
              <p className="text-neutral-8 text-[16px] mb-[45px]">
                Join Newsletter
              </p>
              <p className="text-neutral-8 text-[14px]">
                Subscribe our newsletter to get more informations
              </p>
              <p className="text-neutral-8 text-[14px]">
                about Aizy
              </p>
              <div className="mt-4 flex">
                <div className="relative flex-grow">
                  <input
                    type="text"
                    placeholder="Enter your email"
                    className="text-neutral-8 border-[2px] bg-neutral-1 border-neutral-6 rounded-full px-4 py-2 focus:outline-none w-full pr-[50px]"
                  />
                  <button type="button" className="absolute top-[5px] right-[8px] bg-primary-1 rounded-full p-2 flex items-center" onClick={()=>{}}>
                    <FiArrowRight className="text-neutral-8" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr className="border-neutral-6 mt-[48px] mb-[32px]" />
          <div className="flex items-center justify-between">
            <div className="text-gray-500 text-[12px]">
              Copyright &copy; 2023 Aizy. All rights reserved
            </div>
            <div className="flex items-center">
              <p className="text-neutral-6 text-[12px] mr-[16px]">
                We use cookies for better service.
              </p>
              <a
                href="/accept"
                className="text-primary-1 font-bold text-[12px]"
              >
                Accept
              </a>
            </div>
          </div>
        </div>
        <ScrollToTopButton />
      </footer>
    </div>
  );
}

export default Footer;
