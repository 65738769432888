import React from 'react';
import Home from "../../assets/main.png";

function Features() {
  return (
    <div className="flex justify-between items-center flex-col">
      <h1 className="text-3xl pb-10">Features</h1>
      <div className="flex justify-around items-center flex-1 gap-4 flex-col md:flex-row">
        <div className="md:w-[45vw] w-[60vw]">
          <img className="" src={Home} alt=""/>
        </div>
        <div className="w-96 flex flex-col gap-6">
          <div className="">
            <h2 className="font-bold">Ease of use</h2>
            <p>You just have to plug a bot with you
              with your favorite exchange platform
              and you are ready to go !</p>
          </div>
          <div className="">
            <h2 className="font-bold">Clarity</h2>
            <p>Every bot share public information
              to help you make the best choice</p>
          </div>
          <div className="">
            <h2 className="font-bold">Efficacity</h2>
            <p className="">Every bot is backtested before running
              on Aizy to find out its performance</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features