import React from 'react';
import NavbarNoAccount from './components/NavbarNoAccount';
import Footer from "./components/Footer";
import Hero from "./Views/Hero";
import Features from "./Views/Features";
import Members from "./Views/Members";
import Testimonials from "./Views/Testimonials";
import Content from "./Views/Contents";

function App() {
  window.location = "https://aizy.pages.dev/";

  return (
    <div className="flex flex-col gap-24 overflow-hidden bg-neutral-1 text-neutral-7">
      <NavbarNoAccount/>
      <Hero/>
      <Features/>
      <Content/>
      <Members/>
      <Testimonials/>
      <Footer/>
    </div>
  );
}

export default App;