import React from 'react';

function NavbarNoAccount() {
  return (
    <nav className="border-b-2 border-neutral-3">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center space-x-8">
            <div className="flex items-center border-r-2 border-neutral-6 pr-4">
              <a
                href="/"
                className="text-[24px] font-bold text-neutral-8"
              >
                Aizy
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavbarNoAccount;
