import React from "react";
import William from "../../assets/tmp/PP1.png"
import Marine from "../../assets/tmp/PP2.png"
import Antoine from "../../assets/tmp/PP3.png"
import Jeremie from "../../assets/tmp/PP4.png"
import Clemence from "../../assets/tmp/PP5.png"
import Thibault from "../../assets/tmp/PP6.png"
import Curtis  from "../../assets/tmp/PP7.png"
import MemberCard from "../../components/LandingPage/MemberCard";

function Members() {
  return (
    <div className="flex items-center justify-center flex-col gap-24">
      <div className="flex items-center justify-center flex-col gap-6">
        <h1 className="text-3xl">Who are we ?</h1>
        <p>The Team is composed of 7 computer science student from Lille, France</p>
      </div>
      <div className="flex flex-wrap  gap-6 justify-center items-center w-[90vw] md:w-[70vw]">
        <MemberCard name="Jeremie" role="Front-End" photo={Jeremie}/>
        <MemberCard name="Marine" role="Front-End" photo={Marine}/>
        <MemberCard name="William" role="Front-End" photo={William}/>
        <MemberCard name="Clémence" role="API" photo={Clemence}/>
        <MemberCard name="Antoine" role="API" photo={Antoine}/>
        <MemberCard name="Romain" role="SDK" photo={Curtis}/>
        <MemberCard name="Thibault" role="SDK & CI/CD" photo={Thibault}/>
      </div>
    </div>
  );
}

export default Members;